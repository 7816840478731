import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import FormRegister from "../../components/Expo/FormRegister";
import HeaderExpo from "../../components/Expo/HeaderExpo";
import Footer from "../layouts/footer/Footer";
import HeaderDetail from "../layouts/headerDetail/HeaderDetail";
import "./RegisterStall.scss";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry, registerStall } from "../../redux/reducers/expo";
import { categorygetAll } from "../../redux/reducers/category";
import { useParams } from "react-router-dom";
import expoApi from "../../api/expoApi";
import { Select } from "antd";

const RegisterStall = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm();
  const { Option } = Select;

  const [categoryId, setCategoryId] = useState([
    //{ id: 1, name: "avb" },
    //{ id: 2, name: "ndfb" },
    //{ id: 3, name: "fdfd" },
    //{ id: 3, name: "fdfd" },
  ]);
  const [countryId, setCountryId] = useState();
  const [category, setCategory] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCountry());
    dispatch(categorygetAll());
  }, [id]);

  const { country } = useSelector((state) => state.expoReducer);

  const { categoryList } = useSelector((state) => state.categoryReducer);

  const changeCountry = (data) => {
    setCountryId(data.target.value);
  };
  const changeCate = (dat, data) => {
    setCategoryId(data);
  };
  useEffect(() => {
    categoryId.map((it) =>
      setCategory([...category, { id: it.value, name: it.name }])
    );
  }, [categoryId]);
  const onsubmit = (data) => {
    data.category = category;
    data.country_id = parseInt(countryId);
    data.type = 1;
    data.event_id = parseInt(id);
    console.log(data);
    dispatch(registerStall(data));
    window.scrollTo(0, 0);
    reset();
  };
  return (
    <div>
      <HeaderDetail />
      <HeaderExpo text={"Triển lãm trong nước / Đăng ký gian hàng"} />
      <div className="container">
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className="boxForm">
            <p className="title">Thông tin doanh nghiệp</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"name_enterprise"}
                  label={"Tên doanh nghiệp"}
                  placeholder={"Nhập tên doanh nghiệp"}
                />
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"address"}
                  label={"Địa chỉ"}
                  placeholder={"Nhập địa chỉ"}
                />
              </div>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "start",
                    marginBottom: "18px",
                  }}
                >
                  <label style={{ color: "rgb(78, 172, 43)" }}>Quốc tịch</label>
                  <select
                    style={{
                      height: "36px",
                      borderRadius: "5px",
                      borderColor: "#ced4da",
                    }}
                    onChange={changeCountry}
                  >
                    <option>Chọn quốc tịch</option>
                    {country?.map((it) => (
                      <option value={it?.id} key={it?.id}>
                        {it?.name}
                      </option>
                    ))}
                  </select>
                </span>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"website"}
                  label={"Website"}
                  placeholder={"Nhập website"}
                />
              </div>
            </div>
          </div>
          <div className="boxForm">
            <p className="title">Thông tin người đại diện</p>{" "}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"full_name"}
                  label={"Họ tên"}
                  placeholder={"Nhập họ tên"}
                />
                <FormRegister
                  rules={{
                    required: "Vui lòng nhập trường này",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email không đúng định dạng",
                    },
                  }}
                  control={control}
                  name={"email"}
                  label={"Email"}
                  placeholder={"Nhập email"}
                />
              </div>
              <div style={{ width: "calc(100% / 2 - 20px)" }}>
                <FormRegister
                  rules={{ required: "Vui lòng nhập trường này" }}
                  control={control}
                  name={"position"}
                  label={"Chức vụ"}
                  placeholder={"Nhập chức vụ"}
                />
                <FormRegister
                  rules={{
                    required: "Vui lòng nhập trường này",
                    pattern: {
                      value:
                        /^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/,
                      message: "Số điện thoại không đúng định dạng",
                    },
                  }}
                  control={control}
                  name={"phone"}
                  label={"Số điện thoại"}
                  placeholder={"Nhập số điện thoại"}
                />
              </div>
            </div>
          </div>
          <div className="boxForm">
            <p className="title">Thông tin gian hàng</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormRegister
                rules={{ required: "Vui lòng nhập trường này" }}
                style={"calc"}
                control={control}
                name={"name_department"}
                label={"Tên gian hàng"}
                placeholder={"Nhập tên gian hàng"}
              />
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "start",
                  marginBottom: "18px",
                  width: "calc(100% / 2 - 20px)",
                }}
              >
                <label style={{ color: "rgb(78, 172, 43)" }}>
                  Danh mục gian hàng
                </label>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Chọn danh mục"
                  onChange={changeCate}
                  optionLabelProp="label"
                >
                  {categoryList.map((it) => (
                    <Option
                      // value={`{id:${it.id},name:"${it.name}"}`}
                      // value={`{"id":${it.id},"name":"${it.name}"}`}
                      value={it?.id}
                      label={it?.name}
                      name={it?.name}
                    >
                      <div className="demo-option-label-item">{it?.name}</div>
                    </Option>
                  ))}
                </Select>
              </span>
            </div>
            <div>
              <label style={{ color: "#2a58ff", float: "left" }}>Mô tả</label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    style={{
                      width: "100%",
                      height: "300px",
                      borderRadius: "4px",
                      boxShadow: "inset 0px 0px 4px rgba(0, 0, 0, 0.30)",
                      border: "none",
                    }}
                    placeholder="Nhập nội dung mô tả"
                  />
                )}
              />
            </div>
          </div>
          <div style={{ marginTop: "80px" }}>
            <span
              onClick={handleSubmit(onsubmit)}
              style={{
                padding: "10px 20px",
                background: "#2a58ff",
                color: "white",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Đăng ký
            </span>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default RegisterStall;
