import React, { useEffect } from "react";
import HeaderExpo from "../../components/Expo/HeaderExpo";
import Footer from "../layouts/footer/Footer";
import HeaderDetail from "../layouts/headerDetail/HeaderDetail";
import image from "../../assets/images/supplier/banner.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getExpo } from "../../redux/reducers/expo";
import { useNavigate, useParams } from "react-router-dom";
import imageFile from "../../assets/images/upload/img.png";
import { Col, Pagination, Row } from "antd";
import { useState } from "react";
import { hotNews } from "../../redux/reducers/news";
const DomesticFairs = ({ text }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(1);
  const [size, setSize] = useState(6);
  useEffect(() => {
    const data = { id: id, type: 5, size: size, index: index };
    dispatch(getExpo(data));
    dispatch(hotNews());
  }, [id, index]);
  const { listExpo } = useSelector((state) => state.expoReducer);
  const { listHotNews } = useSelector((state) => state.newsReducer);

  const onChange = (page, pageSize) => {
    setIndex(page);
  };
  console.log("c", listHotNews);
  return (
    <div>
      <HeaderDetail />
      <HeaderExpo text={text} />
      <div className="container">
        <div style={{ display: "flex", width: "100%", gap: "80px" }}>
          <Row>
            <Col span={16}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  marginBottom: "40px",
                }}
              >
                {listExpo?.data?.map((it) => (
                  <div
                    onClick={() => navigate(`/domestic-fairs-detail/${it?.id}`)}
                    style={{
                      display: "flex",
                      gap: "15px",
                      width: "100%",
                    }}
                  >
                    {it.image_url.length !== 0 ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${it?.image_url[0]?.url}`}
                        alt=""
                        style={{
                          width: "calc(30%)",
                          height: "260px",
                          borderRadius: "12px",
                        }}
                      />
                    ) : (
                      <img
                        src={imageFile}
                        style={{
                          width: "calc(30%)",
                          height: "260px",
                          borderRadius: "12px",
                        }}
                      />
                    )}
                    <div style={{ width: "60%" }}>
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        {it?.name}
                      </p>
                      <p style={{ display: "flex", gap: "20px" }}>
                        <span>
                          Đăng bởi:{" "}
                          <span style={{ color: "#F47319" }}>The Mint</span>
                        </span>
                        <span style={{ color: "#369313" }}>
                          {" "}
                          <span
                            style={{
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              display: "inline-block",
                              background: "#369313",
                            }}
                          />{" "}
                          Đang diễn ra
                        </span>
                      </p>
                      <p style={{ textAlign: "left" }}>
                        20/10/2022 - 09:00 -&gt; 11:00
                      </p>
                      <p style={{ textAlign: "left" }}>
                        Notably, the new Galaxy Watches will be Samsung’s first
                        to not use Tizen OS. Google collaborated with Samsung to
                        revamp Wear OS from the ground up, making it smoother
                        and more efficient.
                        oiasdoiaiosjdaisjiodaiosjdioajsiojdioajsiojaiojdioajiodjaiojiodajiodajiojaiojdioajoidaoi
                        adasdasf asfgr htrh kj jghkh...
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <Pagination
                  pageSize={size}
                  total={listExpo?.total_pages}
                  showSizeChanger={false}
                  onChange={onChange}
                />
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div
                  style={{
                    background: "rgba(54, 147, 19, 0.1)",
                    padding: "5px",
                  }}
                >
                  <span
                    style={{
                      // padding:"10px 180px 10px 180px",
                      // background:"rgba(54, 147, 19, 0.1)",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#369313",
                    }}
                  >
                    Tin mới nhất
                  </span>
                </div>
                <div>
                  {listHotNews.map((itemHotNews) => (
                    <p
                      key={itemHotNews?.id}
                      style={{
                        fontWeight: "500",
                        fontSize: "17px",
                        borderBottom: "1px dashed #369313",
                        textAlign: "left",
                        padding: "10px",
                      }}
                    >
                      {itemHotNews?.des}
                    </p>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          {/*<div
            style={{
              width: "calc(60%)",
              display: "flex",
              gap: "35px",
              cursor: "pointer",
              flexDirection: "column",
            }}
        ></div>*/}

          {/*<div style={{ width: "calc(40%)" }}></div>*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DomesticFairs;
