import axiosClient from "./axiosClient";

const productApi = {
  async getAll(page, size, type) {
    const url = `/products/all-paging?page_index=${page}&page_size=${size}&type=${type}`;
    return axiosClient.get(url);
  },
  async getDetail(id) {
    const url = `/products/get/${id}`;
    return axiosClient.get(url);
  },
  async search() {
    const url = `/products/all-paging?name=a&page_index=1&page_size=5&price_start=10&price_end=1000000`;
    return axiosClient.get(url);
  },
  async getForYou(page, size) {
    const url = `/products/all-paging?page_index=${page}&page_size=${size}`;
    return axiosClient.get(url);
  },
  async getProductLike() {
    const url = `/likes/get-like-product`;
    return axiosClient.get(url);
  },
  async addProductLike(data) {
    const url = `/likes/`;
    return axiosClient.post(url, data);
  },
  async getProBestSeller() {
    const url = `/products/all-paging?type=best-seller`;
    return axiosClient.get(url);
  },
};

export default productApi;
