import React, {useEffect, useState} from "react";
import "./HeaderDropdown.scss";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
// import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {Badge, Drawer, Menu, message} from "antd";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {useDispatch, useSelector} from "react-redux";
import {likeProduct} from "../../../redux/reducers/product";
import {getCartAll} from "../../../redux/reducers/cart";

function HeaderDropdown() {
  const dispatch = useDispatch();
  const [sideBar, setSideBar] = useState({ visible: false });

  useEffect(() => {
    dispatch(likeProduct());
    dispatch(getCartAll());
  }, [dispatch]);

  const { listProductLike } = useSelector((state) => state.productReducer);

  const showDrawer = () => {
    setSideBar({ visible: true });
  };
  const onClose = () => {
    setSideBar({ visible: false });
  };
  const { listCart } = useSelector((state) => state.cartReducer);
  const { user, error } = useSelector((state) => state.userReducer);

  const test = () => {
    let sum = 0;
    for (let i = 0; i < listCart.length; i++) {
      sum += listCart[i].products.length;
    }
    return sum;
  };

  const handleClick = () => {
    if (!user.auth) {
      message.error("Bạn chưa đăng nhập!");
    }
  };

  const leng = listCart?.map((x) => {
    return x.products.length;
  });
  const sum = leng?.reduce((partialSum, a) => partialSum + a, 0);
  return (
    <div className="header-dropdown">
      <div className="header-nav-menu-mb">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={showDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          title="Mobifone"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={sideBar.visible}
        >
          {/* <LeftMenu />
              <RightMenu /> */}
          {/* <HeaderNav /> */}
          <Menu mode="horizontal">
            <Menu.Item key="mail">
              <Link to="/">TRANG CHỦ</Link>
            </Menu.Item>

            <Menu.Item key="alipay">
              <Link to="/supplier">NHÀ CUNG CẤP</Link>
            </Menu.Item>
            <Menu.Item key="alipay">
              <Link to="/supplier">NHÀ CUNG CẤP</Link>
            </Menu.Item>
            <Menu.Item key="alipay">
              <Link to="/supplier">NHÀ CUNG CẤP</Link>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
      {/* <div className="header-dropdown-favorits" onClick={handleClick}>
        <Link to="/favorites">
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <img src={favor} alt="" />
            </Badge>
          </IconButton>
        </Link>
      </div> */}
      <div className="header-dropdown-profile">
        {user.auth ? (
          <>
            <Link to="/account/product-favourite">
              <Badge count={listProductLike?.length} size="default">
                <FavoriteBorderIcon style={{ fontSize: "28px" }} />
              </Badge>
            </Link>
          </>
        ) : (
          <div onClick={handleClick}>
            <Badge count={0} size="default">
              <FavoriteBorderIcon style={{ fontSize: "28px" }} />
            </Badge>
          </div>
        )}
      </div>

      <div className="header-dropdown-profile">
        {user.auth ? (
          <>
            <Link to="/cart">
              <Badge count={sum} size="default">
                <ShoppingCartCheckoutIcon style={{ fontSize: "28px" }} />
              </Badge>
            </Link>
          </>
        ) : (
          <div onClick={handleClick}>
            <Badge count={0} size="default">
              <ShoppingCartCheckoutIcon style={{ fontSize: "28px" }} />
            </Badge>
          </div>
        )}
      </div>
      {/* {token ? <ProfileLogin /> : <></>} */}
    </div>
  );
}

export default HeaderDropdown;
