import {createAsyncThunk} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import orderApi from "../../api/orderApi";
import voucherApi from "../../api/voucherApi";

export const getMyOrder = createAsyncThunk("order/getMyOrder", async (id) => {
    try {
        const result = await orderApi.getMyOrder(id);
        return result;
    } catch (error) {
        console.log(error);
    }
});

export const getOrderDetails = createAsyncThunk("order/getOrderDetails", async (id) => {
    try {
        const result = await orderApi.getOrderDetails(id);
        return result;
    } catch (error) {
        console.log(error);
    }
});

export const getOrderByStatus = createAsyncThunk(
    "order/getOrderByStatus",
    async (data) => {
        try {
            const res = await orderApi.getOrderByStatus(data)
            return res
        } catch (e) {
            console.log(e)
        }
    }
)


export const checkoutCart = createAsyncThunk("order/checkoutCart", async (data) => {
    try {
        const result = await orderApi.orderCountPrice(data);
        return result.data;
    } catch (error) {
        console.log(error);
    }
})

export const applyVoucherStore = createAsyncThunk("order/applyVoucherStore", async (data) => {
    try {
        const result = await voucherApi.applyVoucher(data);
        return result.data;
    } catch (error) {
        console.log(error);
    }
})

export const applyVoucherEcommerce = createAsyncThunk("order/applyVoucherEcommerce", async (data) => {
    try {
        const result = await voucherApi.applyVoucherEcommerce(data);
        return result.data;
    } catch (error) {
        console.log(error);
    }
})

export const changeStatusOrder = createAsyncThunk("order/changeStatusOrder",
    async (data, thunkApi) => {
        try {
            // console.log(data)
            const result = await orderApi.changeStatusOrder(data);
            return result
            //   if(result.status == 200) {
            //     if(data.type === "manage"){
            //       thunkApi.dispatch(getOrderByStatus(data.key))
            //     }else{
            //       thunkApi.dispatch(getMyOrder(data.key))
            //     }
            // }
        } catch (error) {
            console.log(error);
        }
    })
export const returnOrderApi = createAsyncThunk("order/return",
    async (data, thunkApi) => {
        try {
            const result = await orderApi.returnOrder(data);
            return result
        } catch (error) {
            console.log(error);
        }
    })

const orderSlice = createSlice({
    name: "order",
    initialState: {
        loadingproduct: false,
        listMyOrder: [],
        detailsOrder: "",
        dataCheckout: [],
        loadingCheckoutCart: false,
        listOrderByStatus: [],
        loadingOrder: false,
    },
    reducers: {},
    extraReducers: {
        [changeStatusOrder.pending]: (state) => {
            state.loadingOrder = true;
        },
        [changeStatusOrder.rejected]: (state) => {
            state.loadingOrder = false;
        },
        [changeStatusOrder.fulfilled]: (state) => {
            state.loadingOrder = false;
        },

        [getOrderByStatus.pending]: (state) => {
            state.loadingproduct = true;
        },
        [getOrderByStatus.rejected]: (state) => {
            state.loadingproduct = false;
        },
        [getOrderByStatus.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.listOrderByStatus = payload.data;
        },
        [getMyOrder.pending]: (state) => {
            state.loadingproduct = true;
        },
        [getMyOrder.rejected]: (state) => {
            state.loadingproduct = false;
        },
        [getMyOrder.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.listMyOrder = payload.data;
        },
        [checkoutCart.pending]: (state) => {
            state.loadingproduct = true;
            state.loadingCheckoutCart = true;
        },
        [checkoutCart.rejected]: (state) => {
            state.loadingproduct = false;
            state.loadingCheckoutCart = false
        },
        [checkoutCart.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.loadingCheckoutCart = false;
            state.dataCheckout = payload;
        },
        [applyVoucherStore.pending]: (state) => {
            state.loadingproduct = true;
        },
        [applyVoucherStore.rejected]: (state) => {
            state.loadingproduct = false;
        },
        [applyVoucherStore.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.dataCheckout = payload;
        },
        [applyVoucherEcommerce.pending]: (state) => {
            state.loadingproduct = true;
        },
        [applyVoucherEcommerce.rejected]: (state) => {
            state.loadingproduct = false;
        },
        [applyVoucherEcommerce.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.dataCheckout = payload;
        },
        [getOrderDetails.pending]: (state) => {
            state.loadingproduct = true;
        },
        [getOrderDetails.rejected]: (state) => {
            state.loadingproduct = false;
        },
        [getOrderDetails.fulfilled]: (state, {payload}) => {
            state.loadingproduct = false;
            state.detailsOrder = payload.data;
        },
    },
});

export default orderSlice;
