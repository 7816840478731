import React from "react";
import "./LoginFB.scss";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookIcon from "@mui/icons-material/Facebook";
import { useDispatch } from "react-redux";
import { loginFacebook } from "../../../../redux/reducers/user";

function LoginFB() {
  const dispatch = useDispatch()
  const responseFacebook = async (response) => {
    if (response.status !== 'unknown') {
        const data = {
            accessToken: response.accessToken,
            email: response.email,
            name: response.name,
            picture: response.picture,
            id: response.id,
        };

        if (data.id !== undefined) {
            dispatch(loginFacebook(data))
        }
    }
};

  return (
    <>
      <FacebookLogin
        appId="722371848807590"
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        render={(renderProps) => (
            <button
                style={{
                    background: '#6686CA',
                    fontSize: '14px',
                    height: '40px',
                    color: '#ffffff',
                    border: '1px solid #6686CA',
                    borderRadius: '4px',
                    padding: '4px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={renderProps.onClick}
            >
                <FacebookIcon />
                Đăng nhập với Facebook
            </button>
        )}
    />
    </>
  );
}

export default LoginFB;
