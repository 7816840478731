import axiosClient from "./axiosClient";
const expoApi = {
  //page, size,
  async getExpo(data) {
    const url = `/events/all-paging?page_size=${data.size}&page_index=${data.index}&type=${data.type}&category_id=${data.id}`;
    return axiosClient.get(url);
  },
  async getExpoDetail(id) {
    const url = `/events/${id}`;
    return axiosClient.get(url);
  },
  async registerStall(data) {
    const url = `/contacter`;
    return axiosClient.post(url, data);
  },
  async getCountry() {
    const url = `/country/`;
    return axiosClient.get(url);
  },
};

export default expoApi;
