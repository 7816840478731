import React, {useEffect} from "react";
import "./HeaderNav.scss";
import {Link, useNavigate} from "react-router-dom";
import BoxNav from "../../HoverNav/BoxNav";
import {useDispatch, useSelector} from "react-redux";
import {categorygetAll} from "../../../redux/reducers/category";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {getCateNews} from "../../../redux/reducers/news";

const menuNews = [
    {id: "1", name: "Tin khuyến mại", path: "/"},
    {id: "2", name: "Tin sự kiện", path: "/"},
];
const menu = [
    {id: "723", name: "Hội chợ trong nước", path: "/domesticFairs/723"},
    {id: "724", name: "Hội chợ nước ngoài", path: "/foreign-fairs/724"},
];
const menu1 = [
    {id: "1", name: "Khóa học", path: "/training/course"},
    {id: "2", name: "Kiến thức nông nghiệp", path: "/training/knowledge"},
    {id: "3", name: "Hỏi đáp nghề nông", path: "/training/Q&A"},
];

const menuSupport = [
    {id: "1", name: "Hướng dẫn mua hàng", path: "/article/support"},
    {id: "2", name: "Chính sách và quy trình xử lý đổi trả", path: "/article/policy"},
    {id: "3", name: "Chính sách giải quyết khiếu nại", path: "/article/complain"},
];

function HeaderNav() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {categoryList} = useSelector((state) => state.categoryReducer);
    const {listCateNews} = useSelector((state) => state.newsReducer);

    useEffect(() => {
        dispatch(categorygetAll);
        dispatch(getCateNews());
    }, []);


    console.log("xx", listCateNews)
    return (
        <div className="header-nav ">
            <div className="header-link">
                <span className={"nav_text"} style={{cursor:'pointer'}} onClick={()=>navigate("/")}>Trang chủ</span>
            </div>
            <div className="header-link header-dropdown-menu">
                <BoxNav data={categoryList} cate={true}/>
                <span className={"nav_text"}>Danh mục </span>
                <span style={{position: "absolute"}}>
                    <ExpandMoreIcon/>
                </span>
            </div>
            <div className="header-link header-dropdown-menu">
                <BoxNav data={listCateNews} news={true}/>
                <span className={"nav_text"}>Tin tức</span>
                <span style={{position: "absolute"}}>
                    <ExpandMoreIcon/>
                </span>
            </div>
            <div className="header-link header-dropdown-menu">
                <BoxNav data={menuSupport}/>
                <span className={"nav_text"}>Hỗ trợ khách hàng</span>
                <span style={{position: "absolute"}}>
                    <ExpandMoreIcon/>
                </span>
            </div>
            {/*<div className="header-link header-dropdown-menu">*/}
            {/*  <BoxNav data={menu} />*/}
            {/*  <Link to="/">Hội chợ triển lãm</Link>*/}
            {/*  <span style={{ position: "absolute" }}>*/}
            {/*    <ExpandMoreIcon />*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className="header-link header-dropdown-menu">*/}
            {/*  <BoxNav data={menu1} />*/}
            {/*  <Link to="/training">Đào tạo cho người nông dân</Link>*/}
            {/*  <span style={{ position: "absolute" }}>*/}
            {/*    <ExpandMoreIcon />*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<div className="header-link">*/}
            {/*    <Link to="/cooperate">Hỗ trợ khách hàng</Link>*/}
            {/*</div>*/}
            {/*<div className="header-link">*/}
            {/*    <Link to="/group">Hội nhóm</Link>*/}
            {/*</div>*/}

            {/* <div className="header-link">
        <Link to="/">ĐẤU THẦU</Link>
      </div>      */}
        </div>
    );
}

export default HeaderNav;
