import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ButtonAuth from "../../Button/ButtonAuth/ButtonAuth"

import {Controller, useForm} from "react-hook-form";
import './RegisterStore.scss'
import storeApi from "../../../api/storeApi";
import {Box, FormControl, FormLabel, Grid, Modal,} from "@mui/material";

import {getCity, getDistrict, getWard,} from "../../../redux/reducers/address";

import ImageUpload from "../../../assets/images/upload/uploadImage.jpg";

import uploadApi from "../../../api/uploadImage";
import {DatePicker, message} from "antd";

const style = {
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -15%)",
    width: "60%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
};


const RegisterStore = () => {
    const user = JSON?.parse(localStorage.getItem("user"))
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        if (user) {
            setOpen(true)
        } else {
            message.error("Vui lòng đăng nhập")
        }
    };
    const handleClose = () => setOpen(false);

    useEffect(() => {
        dispatch(getCity());
    }, []);
    const {city, district, ward} = useSelector(
        (state) => state.addressReducer
    );

    // const [wardd,setWardd] = useState(ward)


    const dispatch = useDispatch();
    //set state Selected
    const [cityAddress, setCityAddress] = useState();
    const [districtsAddress, setDistrictsAddress] = useState("");
    const [wardAddress, setWardAddress] = useState("");

    //convert variable put to dataForm
    const [cityAddressConvert, setCityAddressConvert] = useState("");
    const [districtsAddressConvert, setDistrictsAddressConvert] = useState("");
    const [wardAddressConvert, setWardAddressConvert] = useState("");
    const [specific, setSpecific] = useState()

    const [dataForm, setDataForm] = useState({});
    const onChangeCity = (e) => {
        dispatch(getDistrict(e.target.value));
        setCityAddress(e.target.value);
        setDistrictsAddress([]);
        // setWardd([]);
        setWardAddress([])
    };

    const onChangeDistricts = (e) => {
        dispatch(getWard(e.target.value));
        setDistrictsAddress(e.target.value);
        // setWardd(ward)
        setWardAddress([]);
    };
    const onChangeWard = (e) => {
        setWardAddress(e.target.value);
    };

    const districtFilter = district.find((c) => c.id == districtsAddress);
    const wardFilter = ward.find((c) => c.id == wardAddress);

    const found = city?.find(obj => {
        return obj?.id == cityAddress;
    });
    useEffect(() => {
        // setCityAddressConvert(city[cityAddress - 1]);
        setCityAddressConvert(found);
        setDistrictsAddressConvert(districtFilter);
        setWardAddressConvert(wardFilter);
    }, [cityAddress, districtsAddress, wardAddress]);

    useEffect(() => {
        setDataForm({
            address: {
                address: specific,
                city: {
                    id: cityAddressConvert?.id,
                    name: cityAddressConvert?.name,
                    ghn_id: cityAddressConvert?.ghn_id,
                },
                district: {
                    id: districtsAddressConvert?.id,
                    name: districtsAddressConvert?.name,
                    ghn_id: districtsAddressConvert?.ghn_id,
                },
                ward: {
                    id: wardAddressConvert?.id,
                    name: wardAddressConvert?.name,
                    ghn_id: wardAddressConvert?.ghn_id,
                },
            },
        });
        // }
    }, [
        cityAddressConvert,
        districtsAddressConvert,
        wardAddressConvert,
        cityAddress,
        districtsAddress,
        wardAddress,
        specific
    ]);
    useEffect(() => {
        (async function () {
            if (!districtFilter) return;
        })();
    }, [districtFilter]);

    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        reset,
    } = useForm(
        //     {
        //   resolver: yupResolver(schema),
        //   defaultValues,
        // }
    );


    const [value, setValue] = React.useState([])
    const useUpload = async (e) => {
        let formData = new FormData();

        for (const key of Object.keys(e.target.files)) {
            formData.append("files", e.target.files[key]);
        }
        try {
            const res = await uploadApi.uploadArray(formData);
            setValue({...value, [e.target.name]: res.url});
            if ((res.message = "Success")) {
                message.success("Upload ảnh thành công");
            }
        } catch (error) {
            message.error("Upload ảnh không thành công");
        }
    };

    const onsubmit = async (data) => {
        const address = dataForm.address
        data.address = address
        data.image_url = [value.image_url?.toString()]
        data.ward_code = address.ward.ghn_id
        data.district_id = address.district.ghn_id


        try {
            const register = await storeApi.registerStore(data)
            if (register?.message === "Success") {
                setOpen(false)
                message.success("Đăng ký cửa hàng thành công")
                if (localStorage.getItem('checked') !== null && localStorage.getItem('checked') === "true") {
                    localStorage.removeItem('user');
                    localStorage.removeItem('access_Token');
                    localStorage.removeItem('refresh_Token');
                } else {
                    localStorage.clear()
                }
                window.location.reload();
            } else if (register?.errors == "STORE_EXIST") {
                window.scrollTo(0, 0)
                message.error("Bạn có một cửa hàng đang trong thời gian chờ phê duyệt")
            } else if(register?.errors == "USER_HAD_STORE"){
                window.scrollTo(0, 0)
                message.error("Bạn đã có một cửa hàng, vui lòng đăng nhập lại")
            }
            console.log(register)
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div>
            <p onClick={handleOpen} className="header-top-text-pc">
                TRỞ THÀNH NHÀ BÁN HÀNG
            </p>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="box-main-login">
                        <div className="box-main-title">
                            <p>Đăng ký bán hàng</p>
                        </div>
                        <div className="box-main-form">
                            <form>
                                <div>
                                    Thông tin cơ bản
                                </div>
                                <Grid container spacing={5} columns={16}>
                                    <Grid item xs={8} style={{marginTop: '15px'}}>
                                        <FormControl
                                            fullWidth={true}
                                            style={{
                                                position: 'relative',
                                                height: '100px'
                                            }}
                                        >
                                            <FormLabel htmlFor="nameStore">Họ và tên</FormLabel>
                                            <input
                                                height={40}
                                                id="full_name"
                                                placeholder="Họ và tên"
                                                {...register("full_name", {required: "Họ và tên không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                    color: "red",
                                                }}
                                            >
                                                {errors.name?.message}
                                            </small>
                                        </FormControl>

                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="mail">Email</FormLabel>
                                            <input
                                                height={40}
                                                id="email"
                                                placeholder="Email"
                                                {...register("email", {required: "email không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.email?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="phoneNumber">Số điện thoại</FormLabel>
                                            <input
                                                height={40}
                                                id="phone"
                                                placeholder="Số điện thoại"
                                                {...register("phone", {required: "Phone không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.phone?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl
                                            fullWidth={true}
                                            style={{
                                                position: 'relative',
                                                height: '100px'
                                            }}
                                        >
                                            <FormLabel htmlFor="nameStore">Tên cửa hàng</FormLabel>
                                            <input
                                                height={40}
                                                id="name"
                                                placeholder="Tên cửa hàng"
                                                {...register("name", {required: "Tên cửa hàng không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                    color: "red",
                                                }}
                                            >
                                                {errors.name?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="desc">Mô tả cửa hàng</FormLabel>
                                            <input
                                                height={40}
                                                id="des"
                                                placeholder="Mô tả cửa hàng"
                                                {...register("des",{
                                                    required: "Mô tả cửa hàng không được để trống"
                                                })}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.des?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="phoneNumber">Mã số thuế</FormLabel>
                                            <input
                                                height={40}
                                                id="personal_number"
                                                placeholder="Mã số thuế"
                                                {...register("personal_number", {required: "Mã số thuế không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.personal_number?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="phoneNumber">Tên chủ tài khoản</FormLabel>
                                            <input
                                                height={40}
                                                placeholder="Tên chủ tài khoản"
                                                {...register("account_owner", {required: "Tên chủ tài khoản không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.account_owner?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel htmlFor="phoneNumber">Chi nhánh</FormLabel>
                                            <input
                                                height={40}
                                                placeholder="Chi nhánh"
                                                {...register("branch_bank", {required: "Chi nhánh không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.branch_bank?.message}
                                            </small>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8} style={{marginTop: '14px'}}>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Tỉnh/Thành phố</FormLabel>
                                            <select
                                                required
                                                id="cars"
                                                onChange={onChangeCity}
                                                value={cityAddress}
                                                style={{
                                                    height: "40px",
                                                    width: "100%",
                                                    paddingLeft: "10px",
                                                    border: "1px solid #c4c4c4",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <option disabled selected hidden value={""}>Tỉnh/Thành phố</option>
                                                {city?.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Quận/Huyện</FormLabel>
                                            <select
                                                required
                                                id="cars"
                                                onChange={onChangeDistricts}
                                                value={districtsAddress}
                                                style={{
                                                    height: "40px",
                                                    width: "100%",
                                                    paddingLeft: "10px",
                                                    border: "1px solid #c4c4c4",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <option disabled selected hidden value={""}>Quận/Huyện</option>
                                                {district?.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Phường/Xã</FormLabel>
                                            <select
                                                required
                                                id="cars"
                                                onChange={onChangeWard}
                                                value={wardAddress}
                                                style={{
                                                    height: "40px",
                                                    width: "100%",
                                                    paddingLeft: "10px",
                                                    border: "1px solid #c4c4c4",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <option disabled selected hidden value={""}>Phường/Xã</option>
                                                {ward?.map((item) => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Địa chỉ cụ thể</FormLabel>
                                            <input
                                                height={40}
                                                onChange={(e) => setSpecific(e?.target?.value)}
                                                control={control}
                                                placeholder={"Địa chỉ cụ thể"}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.desc?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Website</FormLabel>
                                            <input
                                                height={40}
                                                {...register("website",{
                                                    required: "Website không được để trống",
                                                    pattern: {
                                                        value: /^(ftp|http|https):\/\/[^ "]+$/i,
                                                        message: "Url website không đúng định dạng"
                                                }
                                                })}
                                                control={control}
                                                placeholder={"Website"}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.website?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Tên ngân hàng</FormLabel>
                                            <input
                                                height={40}
                                                placeholder="Tên ngân hàng"
                                                {...register("name_bank", {required: "Tên ngân hàng không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.name_bank?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Số tài khoản</FormLabel>
                                            <input
                                                height={40}
                                                placeholder="Số tài khoản"
                                                {...register("number_bank", {required: "Số tài khoản không được để trống"})}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.number_bank?.message}
                                            </small>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div>
                                    Thông tin đăng ký kinh doanh(Doanh nghiệp)
                                </div>
                                <Grid container spacing={5} columns={16}>
                                    <Grid item xs={8} style={{marginTop: '15px'}}>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Số chứng nhận</FormLabel>
                                            <input
                                                type={"number"}
                                                height={40}
                                                id="enterprise_number_store"
                                                placeholder="Mã số doanh nghiệp"
                                                {...register("enterprise_number_store", {
                                                    valueAsNumber: true,
                                                    required: "Số chứng nhận không được để trống"
                                                })}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.enterprise_number_store?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Nơi cấp</FormLabel>
                                            <input
                                                height={40}
                                                id="place_issue_store"
                                                placeholder="Nơi cấp"
                                                {...register("place_issue_store",{
                                                    required: "Nơi cấp không được để trống"
                                                })}

                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.place_issue_store?.message}
                                            </small>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8} style={{marginTop: '14px'}}>
                                        <div className={"pick_date"}>
                                            <FormLabel>Ngày cấp</FormLabel>
                                            <Controller
                                                control={control}
                                                name="date_issue_store"
                                                rules={{
                                                    required: "Ngày cấp không được để trống"
                                                }}
                                                render={({field}) => (
                                                    <DatePicker
                                                        placeholder="Chọn ngày"
                                                        onChange={(_, dateString) => field.onChange(dateString)}
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            position: 'absolute',
                                                            bottom: 0,
                                                        }}
                                                    >
                                              {errors.date_issue_store?.message}
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div>
                                    Thông tin đăng ký kinh doanh(Tổ chức)
                                </div>
                                <Grid container spacing={5} columns={16}>
                                    <Grid item xs={8} style={{marginTop: '15px'}}>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Số chứng nhận</FormLabel>
                                            <input
                                                type={"number"}
                                                height={40}
                                                id="establishment_number"
                                                placeholder="Số chứng nhận"
                                                {...register("establishment_number", {
                                                    valueAsNumber: true,
                                                    required: "Số chứng nhận không được để trống"
                                                })}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.establishment_number?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Nơi cấp</FormLabel>
                                            <input
                                                height={40}
                                                id="place_issue"
                                                placeholder="Nơi cấp"
                                                {...register("place_issue",{
                                                    required: "Nơi cấp không được để trống"
                                                })}
                                                control={control}
                                            />
                                            <small
                                                style={{
                                                    color: "red",
                                                    position: 'absolute',
                                                    bottom: "10px",
                                                }}
                                            >
                                                {errors.place_issue?.message}
                                            </small>
                                        </FormControl>
                                        <FormControl style={{height: '100px', position: 'relative'}} fullWidth={true}>
                                            <FormLabel>Ảnh đại diện</FormLabel>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                }}
                                            >
                                                <FormLabel htmlFor="idUpload">
                                                    <img
                                                        alt="Upload"
                                                        src={ImageUpload}
                                                        width="50px"
                                                        height="50px"
                                                    />
                                                </FormLabel>
                                                <input
                                                    height={40}
                                                    onChange={useUpload}
                                                    type="file"
                                                    name="image_url"
                                                    accept="image/*"
                                                    id="idUpload"
                                                    style={{display: "none"}}
                                                    layout="fill"
                                                    // multiple
                                                />
                                                {value.image_url && (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {value.image_url.map((image, index) => (
                                                            <img
                                                                src={`${process.env.REACT_APP_API_URL}/${image}`}
                                                                alt="error"
                                                                key={index}
                                                                width="50px"
                                                                height="50px"
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8} style={{marginTop: '14px'}}>
                                        <div className={"pick_date"}>
                                            <FormLabel>Ngày cấp</FormLabel>
                                            <Controller
                                                control={control}
                                                name="date_issue"
                                                rules={{
                                                    required: "Ngày cấp không được để trống"
                                                }}
                                                render={({field}) => (
                                                    <DatePicker
                                                        placeholder="Chọn ngày"
                                                        onChange={(_, dateString) => field.onChange(dateString)}
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                                    <span
                                                        style={{
                                                            color: "red",
                                                            position: 'absolute',
                                                            bottom: 0,
                                                        }}
                                                    >
                                              {errors.date_issue?.message}
                                            </span>
                                        </div>
                                    </Grid>
                                </Grid>

                                <ButtonAuth text={"Đăng ký"} onClick={handleSubmit(onsubmit)}/>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};
export default RegisterStore;
