import React from 'react';
import './ButtonAuth.scss'

const ButtonAuth = ({text,onClick = () => {}}) => {
	return (
		<div className={"box-button-auth"}>
			<span style={{cursor:'pointer'}} onClick={onClick}>{text}</span>
		</div>
	);
};

export default ButtonAuth;