import React from "react";
import banner from "../../../assets/images/upload/bannerRegister.png";

const Header = ({ title }) => {
  console.log("x", title);
  return (
    <div style={{ position: "relative" }}>
      <img src={banner} style={{ width: "100%", height: "267px" }} />
      <div
        style={{
          display: "flex",
          position: "absolute",
          top: "75px",
          padding: "0 65px",
        }}
        className="container"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
            color: "white",
            width: "70%",
            gap: "25px",
          }}
        >
          <span style={{ fontSize: "28px", fontWeight: "500" }}>{title}</span>
          <span>
            DA VINCI ACADEMY - Block C, Tòa nhà Petro Landmartk, 69 Mai Chí Thọ,
            Quận Thủ Đức, Thành Phố Hồ Chí Minh Tuesday, 20 September 2022, 8:00
            PM+0700
          </span>
        </div>
      </div>
    </div>
  );
};
export default Header;
