import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import newsApi from "../../api/newsApi";

export const getCateNews = createAsyncThunk("news/getCateNews", async () => {
  const getCateNews = await newsApi.getCateNews();
  return getCateNews;
});
export const listNews = createAsyncThunk("news/getlistNews", async (data) => {
  const { page, size ,category_id} = data;
  const listNews = await newsApi.getList(page, size,category_id);
  return listNews;
});

// export const getAlllistNews = createAsyncThunk("news/getlistNews", async (data) => {
//   const { page, size } = data;
//   const listNews = await newsApi.getList(page, size);
//   return listNews;
// });

export const newsDetail = createAsyncThunk("news/getdetailNews", async (id) => {
  const newsDetail = await newsApi.getDetail(id);
  return newsDetail;
});

export const hotNews = createAsyncThunk("news/getHotNews", async () => {
  const hotNews = await newsApi.getHotNews();
  return hotNews;
});

const newSlice = createSlice({
  name: "news",
  initialState: {
    listNew: [],
    listCateNews: [],
    detailNews: {},
    loadingproduct: false,
    loadingCateNews: false,
    total_page: 1,
    listHotNews: [],
  },
  reducers: {},
  extraReducers: {
    [getCateNews.pending]: (state) => {
      state.loadingCateNews = true;
    },
    [getCateNews.rejected]: (state) => {
      state.loadingCateNews = false;
    },
    [getCateNews.fulfilled]: (state, action) => {
      state.loadingCateNews = false;
      state.listCateNews = action.payload.data;
      state.total_page = action.payload.data?.total_pages;
    },

    [listNews.pending]: (state) => {
      state.loadingproduct = true;
    },
    [listNews.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [listNews.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listNew = action.payload.data;
      state.total_page = action.payload.data?.total_pages;
    },
    [newsDetail.pending]: (state) => {
      state.loadingproduct = true;
    },
    [newsDetail.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [newsDetail.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.detailNews = action.payload.data;
      // state.total_page = action.payload.data.total_pages;
    },
    [hotNews.pending]: (state) => {
      state.loadingproduct = true;
    },
    [hotNews.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [hotNews.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listHotNews = action.payload.data;
      // state.total_page = action.payload.data.total_pages;
    },
  },
});

export default newSlice;
