import React, { useRef, useState } from "react";
import "./HeaderTop.scss";
import RegisterStore from "../../../../components/Store/RegisterStore/RegisterStore";
import ProfileLogin from "../../../../components/Profile/ProfileLogin/ProfileLogin";
import profile from "../../../../assets/images/header/profile.svg";
import { Link } from "react-router-dom";
import Login from "../../../../components/Auth/Login/Login";
import Register from "../../../../components/Auth/Register/Register";
import { useSelector } from "react-redux";
import ModalLogin from "../../../../components/Auth/Login/modal/ModalLogin";
function HeaderTop() {
  const loginRef = useRef();
  const { user } = useSelector((state) => state.userReducer);
  return (
    <div className="header-top">
      <div className="header-top-text">
        <p className="header-top-text-pc">
          {user?.data?.type === 5 ? "" : <RegisterStore />}
        </p>
        {/* <p className="header-top-text-mobile">+ 84 - xxxxxxxxxx</p> */}
        {/* responsive mobile */}
        {/* <p className="header-top-mobile">+84-xxxxxxxxxx</p> */}
      </div>
      <div className="header-top-language ">
        {user?.data ? (
          <div className="header-dropdown-profile">
            <ProfileLogin />
            <Link to="/account">
              <span
                style={{
                  display: "flex",
                  paddingRight: "15px",
                  borderRight: "1px solid #737373",
                  color: "#737373",
                }}
              >
                <img style={{ width: "17px" }} src={profile} alt="" />
                <p style={{ margin: "0", inlineSize: "max-content" }}>
                  {user?.data?.full_name}
                </p>
              </span>
            </Link>
          </div>
        ) : (
          <div id="auth">
            <Login />
            {/*<span onClick={()=>loginRef?.current?.open()}>Đăng nhập</span>*/}
            <Register />
          </div>
        )}
        {/*<select id="language">*/}
        {/*  <option value="vietnamese"> Vietnamese </option>{" "}*/}
        {/*  <option value="english"> English </option>{" "}*/}
        {/*</select>*/}
      </div>
      <ModalLogin ref={loginRef} />
    </div>
  );
}

export default HeaderTop;
