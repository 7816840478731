import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import userApi from "../../api/userApi";
import { openNotification } from '../../components/Alert/AlertProduct';
import { toast } from "react-toastify";
import {message} from "antd";
export const getMyInfo = createAsyncThunk(
    "user/getMyInfo",
    async (data, { rejectWithValue }) => {
        const info = await userApi.myInfo();

        if (!info.data) {
            return rejectWithValue("No user found");
        }
        return info;
    }
);

export const updateInfoUser = createAsyncThunk(
    "user/updateInfoUser",
    async (data,thunkApi) => {
    const update = await userApi.update(data);
    if(update.status == 200) {
        thunkApi.dispatch(getMyInfo())
        message.success('Cập nhật thông tin thành công')
    }
    return update;


});

export const login = createAsyncThunk(
    "user/login",
    async (data, { rejectWithValue }) => {
        const result = await userApi.login(data);
        if (result.data) {
            return result;
        }
        // return result;
        return rejectWithValue("No user found");
    }
);

export const loginGoogle = createAsyncThunk(
    "user/loginGoogle",
    async (data) => {
        const result = await userApi.loginGoogle(data);
        return result;
        
    }
);

export const loginFacebook = createAsyncThunk(
    "user/loginFacebook",
    async (data) => {
        const result = await userApi.loginFacebook(data);
        return result;
        
    }
);


const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            auth: false,
            data: "",
        },
        loading: false,
        error: "",
    },
    reducers: {
        setError(state, action) {
            state.error = action.payload;
        },
    },
    extraReducers: {
        [getMyInfo.pending]: (state) => {
            state.loading = true;
        },
        [getMyInfo.rejected]: (state, { payload }) => {
            state.loading = false;
            state.user.auth = false;
        },
        [getMyInfo.fulfilled]: (state, { payload }) => {
            state.loading = false;
            // state.myInfo = action.payload.data;
            state.user.data = payload.data;
            state.user.auth = true;
        },
        [login.pending]: (state) => {
            state.loading = true;
        },
        [login.rejected]: (state) => {
            state.loading = false;
            state.user.auth = false;
            window.scrollTo(0, 0)
            message.error("Sai tài khoản hoặc mật khẩu")
        },
        [login.fulfilled]: (state, { payload }) => {
            state.loading = false;
            const dataPayload = payload.data;
            // console.log("actionlogin", action.payload);
            if (dataPayload) {
                var access_token = dataPayload.access_token;
                var refresh_token = dataPayload.refresh_token;

                var { access_token, refresh_token, ...result } = dataPayload;
                localStorage.setItem("user", JSON.stringify(dataPayload));
                localStorage.setItem("access_Token", access_token);
                localStorage.setItem("refresh_Token", refresh_token);
                // state.user = dataP;
                state.user.data = result;
                state.user.auth = true;
                message.success("Đăng nhập thành công !")
                window.scrollTo(0, 0)
            }
            // state.error = "Đăng Nhập Thành Công !";
        },
        [loginGoogle.pending]: (state) => {
            state.loading = true;
        },
        [loginGoogle.rejected]: (state) => {
            state.loading = false;
            state.user.auth = false;
        },
        [loginGoogle.fulfilled]: (state, { payload }) => {
            state.loading = false;
            const dataPayload = payload.data;
            if (dataPayload) {
                var access_token = dataPayload.access_token;
                var refresh_token = dataPayload.refresh_token;

                var { access_token, refresh_token, ...result } = dataPayload;
                localStorage.setItem("user", JSON.stringify(dataPayload));
                localStorage.setItem("access_Token", access_token);
                localStorage.setItem("refresh_Token", refresh_token);
                // state.user = dataP;
                state.user.data = result;
                state.user.auth = true;
                message.success("Đăng nhập thành công !")
            }
            // state.error = "Đăng Nhập Thành Công !";
        },
        [loginFacebook.pending]: (state) => {
            state.loading = true;
        },
        [loginFacebook.rejected]: (state) => {
            state.loading = false;
            state.user.auth = false;
        },
        [loginFacebook.fulfilled]: (state, { payload }) => {
            state.loading = false;
            const dataPayload = payload.data;
            if (dataPayload) {
                var access_token = dataPayload.access_token;
                var refresh_token = dataPayload.refresh_token;

                var { access_token, refresh_token, ...result } = dataPayload;
                localStorage.setItem("user", JSON.stringify(dataPayload));
                localStorage.setItem("access_Token", access_token);
                localStorage.setItem("refresh_Token", refresh_token);
                state.user.data = result;
                state.user.auth = true;
                openNotification("success", "Đăng nhập thành công !")
            }
        },
    },
});

export const { setError } = userSlice.actions;

export default userSlice;
