import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import { Button, Modal } from "antd";
const IframeShop = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      `https://api.ecommerce.smiletech.vn:8443/products/all-paging?brand_id=203`,
      {
        headers: {
          ecommerce_id: 71,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const card = {
    width: "368.667px",
    textAlign: "left",
    cursor: "pointer",
  };
  const name = {
    color: `#0a4874`,
    fontSize: "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // width: "100%",
  };
  const price = {
    color: `#333`,
    fontSize: "18px",
  };
  const contentImage = {
    // width: "337px",
    height: "217px",
  };
  const inImage = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  };
  const textProduct = {
    textAlign: "start",
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick}>
        <ArrowBackIosNewIcon />
      </div>
    );
  }

  const settings = {
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="carousel-detail-iframe">
        <Slider {...settings}>
          {data?.map((item) => (
            <a
              href={`https://www.mobifone.vn/mobiFone-shop/product/${item.id}`}
              target="_blank"
            >
              <div className="card-carousel-iframe">
                <div style={contentImage}>
                  <img
                    style={inImage}
                    src={`https://api.ecommerce.smiletech.vn:8443/${item.image_url[0].url}`}
                  />
                </div>
                <div style={textProduct}>
                  <h3 style={name}>{item.name}</h3>

                  <h3 style={price}>
                    {item.price.toLocaleString("de-DE")} VND
                  </h3>
                </div>
              </div>
            </a>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default IframeShop;
