import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import courseApi from "../../api/courseApi";

export const getCourse = createAsyncThunk("course/getCourse", async () => {
  const res = await courseApi.getCourse();
  return res;
});
export const getCourseDetail = createAsyncThunk(
  "course/getCourseDetail",
  async (id) => {
    const res = await courseApi.getCourseDetail(id);
    return res;
  }
);
export const registerCourse = createAsyncThunk(
  "course/registerCourse",
  async (data) => {
    const res = await courseApi.registerCourse(data);
    return res;
  }
);

const courseSlice = createSlice({
  name: "course",
  initialState: {
    listCourse: [],
    courseDetail: null,
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getCourse.pending]: (state) => {
      state.loading = true;
    },
    [getCourse.rejected]: (state) => {
      state.loading = false;
    },
    [getCourse.fulfilled]: (state, action) => {
      state.loading = false;
      state.listCourse = action.payload.data;
    },
    [getCourseDetail.pending]: (state) => {
      state.loading = true;
    },
    [getCourseDetail.rejected]: (state) => {
      state.loading = false;
    },
    [getCourseDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.courseDetail = action.payload.data;
    },
  },
});

export default courseSlice;
