import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "antd";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import LoginNormal from "../LoginNormal/LoginNormal";
import LoginGG from "../LoginGG/LoginGG";
import Box from "@mui/material/Box";
import "../Login.scss";

const style = {
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "720px",
  height: "556px",
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingBottom: 4,
  paddingTop: 4,
  borderRadius: "10px",
};

const ModalLogin = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [display, setDisplay] = useState(false);

  const cancel = () => {
    setShow(false);
  };
  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false),
  }));
  const changeRemember = (e) => {
    setIsChecked(e.target.checked);
  };

  const viewLogin = () => {
    return (
      <div>
        <div className="box-main-login">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <span
              style={{
                border: "1px solid #2a58ff",
                padding: "3px 11px 5px 11px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              // onClick={handleClose}
            >
              x
            </span>
          </div>
          <div style={{ margin: "40px 150px" }}>
            {/*<div style={{*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'center',*/}
            {/*    fontSize: '32px',*/}
            {/*    fontWeight: '500'*/}
            {/*}}>Chào mừng đến Oganic*/}
            {/*</div>*/}
            <div className="box-main-title mb-2 mt-2">
              <p>ĐĂNG NHẬP</p>
            </div>
          </div>
          <div className="box-main-form">
            <div
              style={{
                marginTop: "20px",
                padding: "0 40px",
              }}
            >
              {/* <label>Email/Số điện thoại</label> <br /> */}
              <input
                value={username}
                style={{
                  border: "none",
                  borderBottom: "1px solid #2a58ff",
                  borderRadius: "0",
                }}
                type="text"
                placeholder="Tài khoản"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                marginTop: "20px",
                padding: "0 40px",
              }}
            >
              {/* <label>Mật khẩu </label> <br /> */}
              <input
                style={{
                  border: "none",
                  borderBottom: "1px solid #2a58ff",
                  borderRadius: "0",
                  marginBottom: "10px",
                }}
                onCopy="return false"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type={`${display ? "text" : "password"}`}
                placeholder="Mật khẩu"
              />
              {""}
              <span
                onClick={() => setDisplay(!display)}
                style={{
                  position: "absolute",
                  right: "85px",
                  marginTop: "9px",
                  cursor: "pointer",
                }}
              >
                {display ? (
                  <VisibilityOffIcon style={{ color: "#2a58ff" }} />
                ) : (
                  <RemoveRedEyeOutlinedIcon style={{ color: "#2a58ff" }} />
                )}
              </span>
            </div>
          </div>
          <div className="box-forgot">
            <div className="remember">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={changeRemember}
              />
              <span>Nhớ mật khẩu</span>
            </div>
            <div>
              <span
                to={"/#"}
                // onClick={handleOpenForgot}
              >
                Quên mật khẩu
              </span>
            </div>
          </div>
          <div
            className="box-main-form-forget"
            // onClick={handleClose}
          >
            {/* <ForgotPassword setOpen={setOpen} /> */}
          </div>
          {/* <p className="box-main-or">HOẶC</p> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "36px",
            }}
          >
            {/* <div className="box-main-login-cancel" onClick={handleClose}>
                Hủy bỏ
              </div> */}
            <LoginNormal
              password={password}
              username={username}
              checked={isChecked}
            />
          </div>
        </div>
        <div className="box-main-login-social">
          {/* not update */}
          {/*<LoginGG/>*/}
          {/*<LoginFB />*/}
        </div>
      </div>
    );
  };

  return (
    <Modal
      footer={null}
      className={"detail"}
      title={<span style={{ fontSize: "17px" }}>{""}</span>}
      open={show}
      width={700}
      onCancel={() => cancel()}
    >
      {viewLogin()}
    </Modal>
  );
});

export default ModalLogin;
