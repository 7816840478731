import React from "react";
import { Radio } from "antd";

const paymentMethod = [{ id: "1", name: "Thanh toán OnePay" }];
const ChoosePayment = ({ onChange = () => {} }) => {
  return (
    <div>
      <h3
        style={{
          textAlign: "left",
          padding: "10px",
          borderBottom: "2px solid rgb(171, 171, 171)",
        }}
      >
        Phương thức thanh toán
      </h3>
      <div>
        <Radio.Group onChange={onChange}>
          {paymentMethod.map((paymentMethodItem) => (
            <Radio value={paymentMethodItem?.id}>
              {paymentMethodItem?.name}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default ChoosePayment;
