import React, { useState } from "react";
import "./ForgotPassword.scss";
import ButtonAuth from "../../../Button/ButtonAuth/ButtonAuth";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import userApi from "../../../../api/userApi";
import { ErrorMessage } from "@hookform/error-message";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);
  const [displayPass, setDisplayPass] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("key");

  const onsubmit = async (data) => {
    const rq = { token: token, password: data.password };
    try {
      const newPass = await userApi.newPassword(rq);
      if (newPass.message === "Success") {
        message.success("Đặt lại mật khẩu thành công");
        setTimeout(() => {
          navigate("/");
        }, 1500);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div id="parent">
      <form id="form_login">
        <div className={"box-input-changepass"}>
          <span>Đặt lại mật khẩu</span>
        </div>
        <div className="form-input-forgot" style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <input
              onCopy="return false"
              {...register("password", {
                required: "Vui lòng nhập đầy đủ thông tin",
                pattern: {
                  value: /^(?=.{5,}\d+).*$/,
                  message: "Mật khẩu cần từ 6 ký tự, ít nhất một số",
                },
              })}
              type={displayPass ? "text" : "password"}
              placeholder="Nhập mật khẩu mới"
            />
            <span
              onClick={() => setDisplayPass(!displayPass)}
              style={{
                position: "absolute",
                marginLeft: "220px",
                marginTop: "-30px",
                cursor: "pointer",
              }}
            >
              {displayPass ? (
                <VisibilityOffIcon style={{ color: "#2a58ff" }} />
              ) : (
                <RemoveRedEyeOutlinedIcon style={{ color: "#2a58ff" }} />
              )}
            </span>
          </div>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <small className={"error"}>{message}</small>
            )}
          />
          <br />
          <div style={{ position: "relative" }}>
            <input
              onCopy="return false"
              {...register("confirmPassword", {
                required: "Vui lòng nhập đầy đủ thông tin",
                validate: (val) => {
                  if (watch("password") != val) {
                    return "Mật khẩu không trùng khớp";
                  }
                },
              })}
              type={display ? "text" : "password"}
              placeholder="Nhập lại mật khẩu"
            />
            <span
              onClick={() => setDisplay(!display)}
              style={{
                position: "absolute",
                marginLeft: "220px",
                marginTop: "-30px",
                cursor: "pointer",
              }}
            >
              {display ? (
                <VisibilityOffIcon style={{ color: "#2a58ff" }} />
              ) : (
                <RemoveRedEyeOutlinedIcon style={{ color: "#2a58ff" }} />
              )}
            </span>
          </div>

          <ErrorMessage
            errors={errors}
            name="confirmPassword"
            render={({ message }) => (
              <small className={"error"}>{message}</small>
            )}
          />
        </div>
        <br />
        <ButtonAuth text={"Xác nhận"} onClick={handleSubmit(onsubmit)} />
      </form>
    </div>
  );
};

export default ForgotPassword;
