import { Collapse, Input } from "antd";
import React from "react";
import createArray from "../../../utils/createArray";

const InfoTicket = ({ quantity, onChange = () => {} }) => {
  const { Panel } = Collapse;
  return (
    <div>
      <Collapse bordered={false}>
        {createArray(quantity, 0).map((i, index) => (
          <Panel
            key={index}
            style={{ textAlign: "left" }}
            header="Vé mời tham gia ( Vé có giới hạn, vui lòng đăng ký sớm)"
          >
            <div
              style={{
                border: "1px solid rgb(35 35 35 / 30%)",
                borderRadius: "12px",
                padding: "30px",
              }}
            >
              <span style={{ color: "#667985" }}>Thông tin cơ bản: </span>
              <div style={{ marginTop: "24px" }}>
                <form
                  style={{
                    display: "flex",
                    width: "100%",
                    gap: "20px",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <span>Tên</span>
                      <Input
                        name="contact_name"
                        id={`person-${index}`}
                        placeholder="Nhập tên"
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <span>Email</span>
                      <Input
                        name="email"
                        id={`person-${index}`}
                        placeholder="Nhập email"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <span>Số điện thoại</span>
                      <Input
                        name="phone"
                        id={`person-${index}`}
                        placeholder="Nhập số điện thoại"
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <span>Địa chỉ</span>
                      <Input
                        name="address"
                        id={`person-${index}`}
                        placeholder="Nhập địa chỉ"
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default InfoTicket;
