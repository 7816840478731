import React from "react";
import "./HeaderBody.scss";
import HeaderNav from "../../../../components/Nav/HeaderNav/HeaderNav";
import { Link } from "react-router-dom";
import HeaderDropdown from "../../../../components/Nav/HeaderDropdown/HeaderDropdown";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../../../assets/images/logoMobifone.jpg";
import Search from "../../../../components/Search/Search";
function HeaderBody() {
  return (
    <>
      <div className="wrapper-header-body">
        <div className="lg-search">
          <div className="img-logo">
            <Link to="/" className="vietindustry">
              <img src={logo} style={{ width: 190, height: 100 }} />
            </Link>
          </div>
          <Search />
        </div>
        <HeaderDropdown />
      </div>
      <HeaderNav />
    </>
  );
}

export default HeaderBody;
