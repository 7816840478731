import React from "react";

const HeaderExpo = ({ text }) => {
  return (
    <div>
      <p
        className="container"
        style={{ color: "#2a58ff", padding: "10px 45px", textAlign: "left" }}
      >
        Hội chợ triển lãm / {text}{" "}
      </p>
    </div>
  );
};

export default HeaderExpo;
