import React, { useState } from "react";
import "./Register.scss";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import userApi from "../../../api/userApi";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { message } from "antd";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

function Register() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");

  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm();

  const style = {
    position: "absolute",
    top: "16%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    // height: "65%",
    width: "720px",
    height: "662px",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius: "10px",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseForm = () => {
    setOpen(false);
  };

  const [display, setDisplay] = useState(false);
  const [displayPass, setDisplayPass] = useState(false);

  const data = {
    user_name: email,
    password: password,
    phone: phone,
    full_name: fullName,
  };
  const submitForm = async (data) => {
    const body = {
      user_name: data?.email,
      password: data?.password,
      phone: data?.phone,
      full_name: data?.fullName,
    };
    try {
      const registerData = await userApi.registerToken(body);

      if (
        registerData.message == "Success" ||
        registerData.errors == "CUSTOMER_HAD_EXIST_BUT_NOT_ACTIVE"
      ) {
        message.success("Bạn vui lòng vào email để xác nhận");
        setOpen(false);
      }

      const data = {
        user_name: email,
        password: password,
        phone: phone,
        full_name: fullName,
      };
      const submitForm = async (data) => {
        const body = {
          user_name: data?.email,
          password: data?.password,
          phone: data?.phone,
          full_name: data?.full_name,
        };
        try {
          const registerData = await userApi.registerToken(body);
          window.scrollTo(0, 0);
          if (
            registerData.message == "Success" ||
            registerData.errors == "CUSTOMER_HAD_EXIST_BUT_NOT_ACTIVE"
          ) {
            message.success("Bạn vui lòng vào email để xác nhận");
            setOpen(false);
            reset();
            window.scrollTo(0, 0);
          }
          if (registerData.errors == "CUSTOMER_EXIST") {
            message.error("Tài khoản đã được đăng ký");
          }
          if (registerData.errors == "CUSTOMER_MAIL_EXIST_OR_PHONE") {
            message.error("Email hoặc số điện thoại đã tồn tại");
          }
          return registerData;
        } catch (error) {
          console.log(error);
        }
      };
      return registerData;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div
        style={{ paddingRight: "10px", cursor: "pointer" }}
        onClick={handleOpen}
      >
        ĐĂNG KÝ
      </div>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleCloseForm}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="box-main-login">
            <div style={{ display: "flex", justifyContent: "end" }}>
              <span
                style={{
                  border: "1px solid #2a58ff",
                  padding: "3px 11px 5px 11px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleCloseForm}
              >
                x
              </span>
            </div>
            <div className="box-main-form">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: "500",
                    margin: "0",
                  }}
                >
                  Đăng ký
                </p>
              </div>
              <div className="box-register" style={{ padding: "40px 70px" }}>
                <div className="box-main-phone">
                  <div
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      position: "relative",
                      paddingBottom: "5px",
                    }}
                  >
                    <input
                      style={{
                        border: "none",
                        borderBottom: "1px solid #2a58ff",
                        borderRadius: "0",
                      }}
                      type="text"
                      placeholder="Họ và tên"
                      name={"full_name"}
                      {...register("full_name", {
                        required: "Vui lòng nhập họ và tên",
                        minLength: {
                          value: 4,
                          message: "Họ và tên từ 3 ký tự trở lên",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="full_name"
                      render={({ message }) => (
                        <small
                          className={"error"}
                          style={{
                            position: "absolute",
                            left: "13px",
                            bottom: "-15px",
                            color: "red",
                          }}
                        >
                          {message}
                        </small>
                      )}
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: "20px",
                      position: "relative",
                      paddingBottom: "5px",
                    }}
                  >
                    <input
                      style={{
                        border: "none",
                        borderBottom: "1px solid #2a58ff",
                        borderRadius: "0",
                      }}
                      type="tel"
                      placeholder="Số điện thoại"
                      {...register("phone", {
                        required: "Vui lòng nhập số điện thoại",
                        pattern: {
                          value: /((09|03|07|08|05)+([0-9]{8})\b)/g,
                          message: "Số điện thoại không đúng định dạng",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="phone"
                      render={({ message }) => (
                        <small
                          className={"error"}
                          style={{
                            position: "absolute",
                            left: "13px",
                            bottom: "-15px",
                            color: "red",
                          }}
                        >
                          {message}
                        </small>
                      )}
                    />
                    <br />
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    position: "relative",
                    paddingBottom: "5px",
                  }}
                >
                  <input
                    style={{
                      border: "none",
                      borderBottom: "1px solid #2a58ff",
                      borderRadius: "0",
                    }}
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Vui lòng nhập email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Email không đúng định dạng",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <small
                        className={"error"}
                        style={{
                          position: "absolute",
                          left: "13px",
                          bottom: "-15px",
                          color: "red",
                        }}
                      >
                        {message}
                      </small>
                    )}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                    position: "relative",
                    paddingBottom: "5px",
                  }}
                >
                  <input
                    onCopy="return false"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #2a58ff",
                      borderRadius: "0",
                    }}
                    type={`${displayPass ? "text" : "password"}`}
                    placeholder="Nhập mật khẩu"
                    {...register("password", {
                      required: "Vui lòng nhập mật khẩu",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <small
                        className={"error"}
                        style={{
                          position: "absolute",
                          left: "13px",
                          bottom: "-15px",
                          color: "red",
                        }}
                      >
                        {message}
                      </small>
                    )}
                  />
                  <span
                    onClick={() => setDisplayPass(!displayPass)}
                    style={{
                      position: "absolute",
                      right: "5px",
                      marginTop: "9px",
                      cursor: "pointer",
                    }}
                  >
                    {displayPass ? (
                      <VisibilityOffIcon style={{ color: "#2a58ff" }} />
                    ) : (
                      <RemoveRedEyeOutlinedIcon style={{ color: "#2a58ff" }} />
                    )}
                  </span>
                </div>
                {/* <br /> */}
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "5px",
                  }}
                >
                  <input
                    onCopy="return false"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #2a58ff",
                      borderRadius: "0",
                    }}
                    type={`${display ? "text" : "password"}`}
                    placeholder="Nhập lại mật khẩu"
                    {...register("confirm_password", {
                      required: "Vui lòng nhập lại mật khẩu",
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Mật khẩu không trùng khớp";
                        }
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="confirm_password"
                    render={({ message }) => (
                      <small
                        className={"error"}
                        style={{
                          position: "absolute",
                          left: "13px",
                          bottom: "-15px",
                          color: "red",
                        }}
                      >
                        {message}
                      </small>
                    )}
                  />
                  <span
                    onClick={() => setDisplay(!display)}
                    style={{
                      color: "#2a58ff",
                      position: "absolute",
                      right: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {display ? (
                      <VisibilityOffIcon style={{ color: "#2a58ff" }} />
                    ) : (
                      <RemoveRedEyeOutlinedIcon style={{ color: "#2a58ff" }} />
                    )}
                  </span>
                </div>
              </div>
              {/* <br /> */}
            </div>

            <div
              className="box-main-login-normal"
              style={{ padding: "0 70px" }}
            >
              <div
                className="handle-register"
                onClick={handleSubmit(submitForm)}
              >
                <p>Đăng ký</p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Register;
