import React from "react";
import image from "../../assets/images/upload/Rectangle.png";
const CooperateItem = ({ name, price }) => {
  return (
    <div className="investments-item">
      <div className="investments-image">
        <img src={image} />
        <span className="investments-funds-name">{name}</span>
      </div>
      <div className="investments-detail">
        <div className="investments-detail-content">
          <span className="investments-detail-title">Ưu đãi</span>
          <ul>
            <li>Quản lý khóa học</li>
            <li>Đăng sản phẩm lên gian hàng</li>
            <li>
              Tham gia các sự kiện, hội thảo, hội nghị miễn phí khắp cả nước
            </li>
            <li>Tham gia các khóa đào tạo, tập huấn</li>
          </ul>
        </div>
        <div className="investments-detail-bottom">
          <div className="investments-detail-price">
            <span>{price}</span> / <span>1 Tháng</span>
          </div>
          <div className="investments-detail-action">
            <span className="register-now">Upgrade</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CooperateItem;
