import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import productApi from "../../api/productApi";

export const productgetAll = createAsyncThunk(
  "product/productgetAllAction",
  async (data, { rejectWithValue }) => {
    const { page, size, type } = data;
    const listproduct = await productApi.getAll(page, size, type);
    if (!listproduct.data) {
      return rejectWithValue("No user found");
    }
    return listproduct;
  }
);
export const getproductForYou = createAsyncThunk(
  "product/productForYou",
  async (data) => {
    const { page, size } = data;
    console.log("size", size);
    const listproduct = await productApi.getForYou(page, size);
    return listproduct;
  }
);
export const productDetail = createAsyncThunk("product/detail", async (id) => {
  const productDetail = await productApi.getDetail(id);
  return productDetail;
});

export const likeProduct = createAsyncThunk("product/likeProduct", async () => {
  const res = await productApi.getProductLike();
  return res;
});

export const addProductLike = createAsyncThunk(
  "product/addProductLike",
  async (data, thunkApi) => {
    console.log(data);
    const res = await productApi.addProductLike(data);
    if (res.status == 200) {
      thunkApi.dispatch(productDetail(data.product_id));
    }
    return res;
  }
);
export const productSeller = createAsyncThunk(
  "product/productSeller",
  async () => {
    const res = await productApi.getProBestSeller();
    return res;
  }
);
const productslide = createSlice({
  name: "product",
  initialState: {
    bestSeller: [],
    productlist: [],
    detail: {},
    loadingproduct: false,
    productForYou: [],
    listImageDetail: [],
    filter: "",
    storeDetailId: 1,
    total_page: 1,
    total_pageYou: 1,
    listProductLike: [],
  },
  reducers: {
    // saveFilter: (state, action) => {
    //   state.filter = action.payload;
    // },
  },
  extraReducers: {
    [productSeller.pending]: (state) => {
      state.loadingproduct = true;
    },
    [productSeller.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [productSeller.fulfilled]: (state, action) => {
      state.bestSeller = action.payload.data;
    },

    [productgetAll.pending]: (state) => {
      state.loadingproduct = true;
    },
    [productgetAll.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [productgetAll.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.productlist = action.payload.data;
      state.total_page = action.payload.data.total_pages;
    },
    [productDetail.pending]: (state) => {
      state.loadingproduct = true;
    },
    [productDetail.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [productDetail.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.detail = action.payload.data;
      state.listImageDetail = action.payload.data?.image_url;
      state.storeDetailId = action.payload.data.store?.id;
    },
    [getproductForYou.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getproductForYou.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getproductForYou.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.productForYou = action.payload.data.data;
      state.total_pageYou = action.payload.data.total_pages;
    },
    [likeProduct.pending]: (state) => {
      state.loadingproduct = true;
    },
    [likeProduct.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [likeProduct.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listProductLike = action.payload.data;
    },
  },
});

export default productslide;
