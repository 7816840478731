import axiosClient from "./axiosClient";

const courseApi = {
  async getCourse() {
    const url = `/course`;
    return axiosClient.get(url);
  },
  async getCoursePaging() {
    const url = `/course/all-paging?type=2&page_index=1&page_size=1`;
    return axiosClient.get(url);
  },
  async getCourseDetail(id) {
    const url = `/course/${id}`;
    return axiosClient.get(url);
  },
  async registerCourse(data) {
    const url = `/bookings/`;
    return axiosClient.post(url, data);
  },
};

export default courseApi;
