import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import cooperationApi from "../../api/cooperationApi";

export const getCooperation = createAsyncThunk(
  "cooperation/getCooperation",
  async () => {
    const res = await cooperationApi.getCooperation();
    return res;
  }
);

const cooperationSlice = createSlice({
  name: "cooperation",
  initialState: {
    listCooperation: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getCooperation.pending]: (state) => {
      state.loading = true;
    },
    [getCooperation.rejected]: (state) => {
      state.loading = false;
    },
    [getCooperation.fulfilled]: (state, action) => {
      state.loading = false;
      state.listCooperation = action.payload.data;
    },
  },
});

export default cooperationSlice;
