import React from "react";
import Footer from "../layouts/footer/Footer";
import HeaderDetail from "../layouts/headerDetail/HeaderDetail";
import "./Cooperate.scss";
import CooperateItem from "../../components/Cooperate/CooperateItem";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCooperation } from "../../redux/reducers/cooperation";
const Cooperate = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCooperation());
  }, []);
  const { listCooperation } = useSelector((state) => state.cooperationReducer);
  console.log(listCooperation);
  return (
    <div>
      <HeaderDetail />
      <div className="content-main">
        <div className="cooperate-warp">
          <div className="cooperate-title">
            <h1>Quỹ đầu tư Nông nghiệp</h1>
            <span>Chọn sản phẩm phù hợp với doanh nghiệp bạn</span>
          </div>
          <div className="investments-list">
            {listCooperation?.map((cooperationItems) => (
              <CooperateItem
                name={cooperationItems?.name}
                price={cooperationItems?.price.toLocaleString("de-DE")}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Cooperate;
