import { InputNumber, Space } from "antd";
import React from "react";
const TableLeft = ({ courseDetail, onChange = () => {} }) => {
  return (
    <div>
      <div className="choose-ticket-header">
        <span className="participate">Số người tham gia</span>
        <span className="ticket-price">Giá vé</span>
        <span className="quantity">Số lượng</span>
      </div>
      <div className="choose-ticket-body">
        <span className="content-participate">
          Số lượng người tham gia (Số lượng từ {courseDetail?.amount_min} -{" "}
          {courseDetail?.amount_max} người)
        </span>
        <span className="content-price">
          {courseDetail?.price?.toLocaleString("de-DE")} VND
        </span>
        <span className="content-quantity">
          <Space>
            <InputNumber
              min={0}
              max={courseDetail?.amount_max}
              defaultValue={0}
              onChange={onChange}
            />
          </Space>
        </span>
      </div>
    </div>
  );
};

export default TableLeft;
