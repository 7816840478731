import React from "react";
import {Spin, Space} from "antd";
import logo from "../../assets/images/logoMobifone.jpg"

const Loading = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                minHeight: "100vh",
            }}
        >
            {" "}
            {/*<Spin size="large" />*/}
            <div style={{width:"200px",height:'200px',minHeight:'200px',minWidth:'200px'}}>
                <img src={logo} alt="" style={{width:"200px",height:'200px',objectFit:'contain'}}/>
            </div>
        </div>
    );
};

export default Loading;
