import React from "react";
import "./ProfileLogin.scss";
import profile from "../../../assets/images/header/profile.svg";
import invoice from "../../../assets/images/header/invoice.svg";
import arrowright from "../../../assets/images/header/arrowright.svg";
import { Link } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

function ProfileLogin() {
  return (
    <div className="profile-login">
      <Link to="/account" className="profile-login-item">
        <img src={profile} alt="" />
        <p> Tài khoản</p>
        <img src={arrowright} alt="" className="profile-login-arrow" />
      </Link>
      <div className="line"></div>
      <Link to="/account/order-management" className="profile-login-item">
        <img src={invoice} alt="" />
        <p> Đơn hàng</p>
        <img src={arrowright} alt="" className="profile-login-arrow" />
      </Link>
      <div className="line"></div>
      <div
        className="profile-login-logout"
        onClick={() => {
          if(localStorage.getItem('checked') !== null && localStorage.getItem('checked') === "true"){
              localStorage.removeItem('user');
              localStorage.removeItem('access_Token');
              localStorage.removeItem('refresh_Token');
          }else{
              localStorage.clear()
          }
          window.location.reload();
        }}
      >
          <LogoutIcon/>
        <p>Đăng xuất</p>
      </div>
    </div>
  );
}

export default ProfileLogin;
