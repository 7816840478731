import React from "react";
import { Link } from "react-router-dom";
import "./FooterTop.scss";
import Logo1 from "../../../../assets/images/footer/icon/Visa.svg";
import Logo2 from "../../../../assets/images/footer/icon/Stripe.svg";
import Logo3 from "../../../../assets/images/footer/icon/PayPal.svg";
import Logo4 from "../../../../assets/images/footer/icon/UnionPay.svg";
import Logo5 from "../../../../assets/images/footer/icon/JCB.svg";
import httt from "../../../../assets/images/httt.jpg";
import Logo6 from "../../../../assets/images/footer/icon/Mastercard.svg";
import { message } from "antd";

function FooterTop() {
  return (
    <div className="footer-top">
      <div className="footer-top-list">
        <p
          className="footer-top-title"
          onClick={() => message.success("aaaaaa")}
        >
          Về MobiFone
        </p>
        <div className="footer-top-content">
          <Link to="/article/introduce">Quy chế hoạt động</Link>
        </div>
        {/* <div className="footer-top-content">
          <Link to="/article/juridical">Hồ sơ pháp lý Nhà bán hàng</Link>
        </div> */}
        <div className="footer-top-content">
          <Link to="/article/support">Hướng dẫn mua hàng</Link>
        </div>

        <div className="footer-top-content">
          <Link to="/article/procedure">Quy trình đóng gói hàng hoá</Link>
        </div>
      </div>
      <div className="footer-top-list">
        <p className="footer-top-title">Chinh sách và quy định</p>
        <div className="footer-top-content">
          <Link to="/article/commodity">Chính sách hàng hóa</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/policy">
            Chính sách và quy trình xử lý đổi trả
          </Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/complain">Chính sách giải quyết khiếu nại</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/security">Bảo mật</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/transport">Vận chuyển giao nhận</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/handling-violation">Xử lý vi phạm</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/payments">Hình thức thanh toán</Link>
        </div>
        <div className="footer-top-content">
          <Link to="/article/dispute-resolution">
            Giải quyết tranh chấp và các biện pháp xử lý
          </Link>
        </div>
      </div>
      <div className="footer-top-list">
        <p className="footer-top-title">Hỗ trợ</p>
        <div className="footer-top-content">
          <span>Hotline: 024.37831800</span>
        </div>
        <div className="footer-top-content">
          <span>Email: contact-itc@mobifone.vn</span>
        </div>
      </div>
      <div className="footer-top-list">
        {/* <img src={httt} alt="" width="300px" /> */}
        <p className="footer-top-title">Phương thức thanh toán</p>
        <div className="footer-top-content">
          <span>Thanh toán khi nhận hàng</span>
        </div>
        <div className="footer-top-content">
          <span>Chuyển khoản</span>
        </div>
        {/* <div className="footer-top-icon">
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo1} />
            </Link>
          </div>
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo2} />
            </Link>
          </div>
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo3} />
            </Link>
          </div>
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo4} />
            </Link>
          </div>
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo5} />
            </Link>
          </div>
          <div className="footer-top-icon-ele">
            <Link to="/">
              <img src={Logo6} />
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default FooterTop;
