import React from "react";
import "./HeaderDetail.scss";
import HeaderTop from "../header/headerTop/HeaderTop";
import HeaderDetailBody from "./headerDetailBody/HeaderDetailBody";
import HeaderBody from "../header/headerBody/HeaderBody";

function HeaderDetail() {
    return (
        <div className="header">
            <HeaderTop />
            <HeaderBody />
        </div>
    );
}

export default HeaderDetail;
