import React from "react";
import FooterBottom from "./footerBottom/FooterBottom";
import FooterTop from "./footerTop/FooterTop";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer">
      <FooterTop />
      <div className="body-footer">
        <div className="title">TỔNG CÔNG TY VIỄN THÔNG MOBIFONE </div>
        <p>
          {" "}
          Giấy chứng nhận đăng ký doanh nghiệp số: 0100686209 do Sở Kế hoạch và
          Đầu tư thành phố Hà Nội cấp lần đầu ngày 20/09/2010.{" "}
        </p>
        <p>
          Địa chỉ trụ sở chính: Số 01 phố Phạm Văn Bạch, Phường Yên Hoà, Quận
          Cầu Giấy, Thành phố Hà Nội, Việt Nam.
        </p>
        <p>Số điện thoại: 024.37831800</p>
        <p>Người đại diện: Ông Tô Mạnh Cường</p>
        <p>Email liên hệ: contact-itc@mobifone.vn</p>
      </div>
      <FooterBottom />
    </div>
  );
}

export default Footer;
