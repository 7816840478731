import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import supplierApi from '../../api/supplierApi'

export const suppliergetHot = createAsyncThunk('supplier/suppliergetHot', async (data) => {
    const supplierHot = await supplierApi.getHot();  
    return supplierHot;
  });

export const supplierGetAll = createAsyncThunk("supplier/supplierGetAll",
  async (data) => {
    const { page, size } = data;
    const listsupplier = await supplierApi.getAll(page, size);
    return listsupplier;
  }
);

const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    supplierList: [],
    loadingsupplier: false,
  },
  reducers: {
    // saveFilter: (state, action) => {
    //   state.filter = action.payload;
    // },
  },

  extraReducers: {
    [supplierGetAll.pending]: (state) => {
      state.loadingsupplier = true;
    },
    [supplierGetAll.rejected]: (state) => {
      state.loadingsupplier = false;
    },
    [supplierGetAll.fulfilled]: (state, action) => {
      state.loadingsupplier= false;
      state.supplierList= action.payload.data;
    },
  }
})

export default supplierSlice
