import axiosClient from "./axiosClient";

const cooperationApi = {
  async getCooperation() {
    const url = `/cooperation-package`;
    return axiosClient.get(url);
  },
};

export default cooperationApi;
