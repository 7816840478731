import axiosClient from "./axiosClient";

const newsApi = {
  async getCateNews() {
    const url = `/news-cate/`;
    return axiosClient.get(url);
  },
  async getList(page, size,category_id) {
    const url = `/news/all-paging?page_size=${size}&page_index=${page}&news_category_id=${category_id}`;
    return axiosClient.get(url);
  },
  async getDetail(id) {
    const url = `/news/${id}`;
    return axiosClient.get(url);
  },
  async getAllDetail(id) {
    const url = `/news`;
    return axiosClient.get(url);
  },
  async getHotNews() {
    const url = `/news/hot-new/all`;
    return axiosClient.get(url);
  },
};

export default newsApi;

